
import { Component, Prop } from 'vue-property-decorator';
import VFormBuilder from '../shared/form/VFormBuilder.vue';
import { InputType } from '@/enums/InputType';
import StackedForm from '../../mixins/StackedForm';
import { mixins } from 'vue-class-component';
import { Role } from '@/interfaces/models/Role';
import { Venue } from '@/interfaces/models/Venue';
import { venueFilter } from '@/util/helper';

@Component({
  components: { VFormBuilder },
})
export default class ClientForm extends mixins(StackedForm) {
  @Prop({ type: Array, required: true }) public roles!: Role[];
  @Prop({ type: Array, required: true }) public venues!: Venue[];

  public $refs!: {
    form: InstanceType<typeof VFormBuilder> & { form: any };
  };

  get items() {
    return [
      { name: 'name', type: InputType.Text, label: 'client.form.name', rules: 'required' },
      { name: 'type', type: InputType.Select, label: 'client.form.type', rules: 'required', items: this.types },
      { name: 'role', type: InputType.Select, label: 'client.form.role', rules: 'required', items: this.roleItems },
      {
        name: 'venues',
        type: InputType.Autocomplete,
        label: 'client.form.venues',
        rules: 'required',
        multiple: true,
        itemValue: '_id',
        itemText: 'name',
        items: this.venues,
        filter: venueFilter,
      },
      { name: 'isBlocked', type: InputType.Checkbox, label: 'client.form.isBlocked', default: false },
      { name: 'canRegister', type: InputType.Checkbox, label: 'client.form.canRegister', default: true },
      { name: 'whitelist', type: InputType.Combobox, label: 'client.form.whitelist', multiple: true },
    ];
  }

  get types() {
    return ['web', 'other', 'terminal', 'tablet'];
  }

  get roleItems() {
    return this.roles.map((r: Role) => ({ text: r.name, value: r._id }));
  }

  public getData() {
    return { ...this.$refs.form.form };
  }
}
