
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import EditTableLoader from '@/components/table/EditTableLoader.vue';
import EditTagLoader from '@/components/tag/EditTagLoader.vue';
import TagForm from '@/components/tag/TagForm.vue';
import { Tag } from '@/interfaces/models/Tag';
import Notification from '@/mixins/Notification';
import ClientForm from '../../components/client/ClientForm.vue';
import { Venue } from '@/interfaces/models/Venue';
import { Role } from '@/interfaces/models/Role';

const client = namespace('client');
const role = namespace('role');
const app = namespace('app');

@Component({
  components: { ClientForm, TagForm, EditTagLoader, EditTableLoader, VWrapper },
})
export default class EditClient extends mixins(Editable, Notification) {
  @app.State('venues') public venues!: Venue[];
  @role.State('items') public roles!: Role[];

  @role.Action('fetch') public getRoles!: any;

  @client.Action('store') public store!: any;
  @client.Action('update') public update!: any;
  @client.Action('show') public show!: any;
  @client.State('active') public active!: Tag;

  public $refs!: {
    form: InstanceType<typeof ClientForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get client() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get title() {
    return this.editing ? 'client.edit' : 'client.create';
  }

  public async mounted() {
    this.$startLoading('role');
    await this.getRoles();
    this.$stopLoading('role');
    if (this.editing) {
      this.$startLoading('client');
      await this.show({ id: this.id });
      this.$stopLoading('client');
    }
    if (this.editing && !this.active) {
      this.$router.push({ name: 'client.index' });
    }
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      if (res) {
        if (this.editing) {
          await this.update({ ...this.$refs.form.getData(), id: this.id });
        } else {
          await this.store({ ...this.$refs.form.getData() });
        }
        this.$router.push({ name: 'client.index' });
      } else {
        this.notifyError('notification.form');
      }
    });
  }
}
